import React from 'react';
import Page from '../components/Page';
import { Typography, Link } from '@mui/material';
import UploadForm from '../components/UploadForm';

export default function Index() {
  return (
    <Page title="Upload Your Pack">
      <Typography variant="body1" gutterBottom>
        Thanks for sharing. Use this form to upload your Cortex XSOAR Pack. Once uploaded we will start a review process
        to help you get your Pack published on the <Link href="https://xsoar.pan.dev/marketplace" target="_blank">Cortex XSOAR Marketplace</Link>.
      </Typography>
      <UploadForm />
    </Page>
  );
}
