import React from 'react';
import { Formik, Field } from 'formik';
import axios, { AxiosError } from 'axios';
import {UPLOAD_URL} from '../constants'
import * as yup from 'yup';
import {
  Button,
  Typography,
  Grid,
  FormLabel,
  FormHelperText,
  LinearProgress,
  Collapse,
  Container,
  } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';
import { Storefront } from '@mui/icons-material';
import { TextField } from 'formik-mui';
import { DropzoneArea } from 'react-mui-dropzone';
import { navigate } from 'gatsby';
import { PaperStyle, AvatarStyle, FormStyle } from '../styles';

interface ServerResponse {
  data: ServerData
}

interface ServerData {
  id: string,
}


const uploadSchema = yup.object().shape({
  email: yup.string().email("Please provide a valid email").required('Required'),
  license_id: yup.string().required('Required'),
  license_name: yup.string().required('Required'),
  file: yup.mixed().required('Please select a Pack to upload'),
});

async function postForm(values: { [id: string]: string | null }) {
  const formData = new FormData()
  for (const [key, value] of Object.entries(values)) {
    if (value) {
      formData.append(key, value)
    }
  }
  console.log('uploading: ', formData)
  const res: ServerResponse = await axios.post(UPLOAD_URL, formData)
  console.log('axios res: ', res)
  void navigate(`/status?id=${res.data.id}`)
}


const UploadForm: React.FC = () => {
  const [errorMsg, setErrorMsg] = React.useState<string | undefined>(undefined);
  return (
    <Formik initialValues={{ email: '', file: null, license_name: '', license_id: '' }}
      validationSchema={uploadSchema}
      onSubmit={(values, { setSubmitting }) => {
        postForm(values)
          .catch((e: AxiosError) => {
            console.log('Failed upload: ', e, e.response)
            let msg: string;
            if (e.isAxiosError && e.response) {
              msg = `${JSON.stringify(e.response.data)} (${String(e.response.status)})`
            }
            else {
              msg = e.message
            }
            setErrorMsg(msg)
          })
          .finally(() => {
            setSubmitting(false)
          })
      }}
    >
      {(props) => {
        const dropzoneErr = props.errors.file && (props.touched.file || props.submitCount > 0) && props.errors.file
        return (
        <PaperStyle maxWidth="md">
          <AvatarStyle >
            <Storefront />
          </AvatarStyle>
          <Typography variant="h5">
            Cortex XSOAR - Upload Pack
          </Typography>
          {/* <CloseAlert mb={3} display={errorMsg != undefined} variant='error' hide={() => setErrorMsg(undefined)} >{errorMsg}</CloseAlert> */}
          <FormStyle noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Collapse in={errorMsg !== undefined}>
                  <Alert severity='error' onClose={() => setErrorMsg(undefined)}>
                    <AlertTitle>Error</AlertTitle>
                    {errorMsg}
                  </Alert>
                </Collapse>
              </Grid>
              <Grid item xs={12}>
                <Field variant="outlined" component={TextField} type="email" name="email" label="Email" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <Field variant="outlined" component={TextField} name="license_name" label="License - Customer Name" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <Field variant="outlined" component={TextField} name="license_id" label="License - ID" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <FormLabel>Pack Zip File</FormLabel>
                <Container sx={{
                    '&& .MuiDropzoneArea-root': {
                    borderColor: dropzoneErr ? 'error.light' : '',
                    backgroundColor: 'action.selected',
                    },
                }} disableGutters>
                <DropzoneArea
                  filesLimit={1}
                  showPreviewsInDropzone={true}
                  showFileNames={true}
                  onChange={(files) => props.setFieldValue('file', files ? files[0] : null)}
                  onDelete={() => props.setFieldTouched('file')}
                />
                </Container>
                {dropzoneErr && <FormHelperText error>{dropzoneErr}</FormHelperText>}
              </Grid>
              <Grid item xs={12}>
                {props.isSubmitting && <LinearProgress />}
                {/* Formik is expecting a type="submit" prop in the button component in order to trigger
                    the onSubmit function prop. see example: https://formik.org/docs/guides/typescript */}
                <Button fullWidth variant="contained" color='primary'
                  disabled={props.isSubmitting} type="submit">Submit</Button>
              </Grid>
            </Grid>
          </FormStyle >
        </PaperStyle>
      )}}
    </Formik >
  )
}

export default UploadForm;
